import React from "react";

import Layout from "../components/Layout";

const AboutPage = () => (
    <Layout>
        <div id="main">
            <div className="about_section_banner">
                <div className="about_banner">
                    <h1>Acerca de Skyler</h1>
                </div>
            </div>
            <div className="inner">
                <div className="about_section_1">
                    <h1 className="about_section_1__title">Conoce más de nosotros</h1>
                    <p className="about_section_1__paragraph">Skyler Finance, es una firma fundada en el 2004. Desde
                        hace más
                        de una década hemos asesorado
                        a empresas de diferentes ramos en la República Mexicana con gran éxito. Gracias a esto
                        contamos con más de mil clientes activos por lo que nos hemos consolidado y mantenido en el
                        concurrido ámbito de la asesoría empresarial, alcanzando un posicionamiento sólido como
                        empresa referente de calidad y excelentes resultados. La relación con nuestros clientes se
                        caracteriza por un alto grado de confianza e intercambio de experiencias, que han
                        contribuido a lograr una base sólida para el desarrollo con éxito de todos nuestros
                        proyectos. En Skyler Finance hemos prestado siempre especial atención a la necesidad de
                        evolucionar y adaptarse a circunstancias específicas, por eso buscaos permanentemente el
                        equilibrio entre cuatro principios fundamentales: Independencia, Objetividad, Competencia y
                        Confiabilidad.</p>
                </div>
            </div>
        </div>
    </Layout>
);

export default AboutPage;
